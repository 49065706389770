<template>
  <b-card title="Update Business Account">
    <b-overlay
      :show="loading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <b-form @submit.prevent="setDataforProfile">
        <b-row>
          <b-col md="12">
            <b-form-group
              class="required"
              label="Business Logo"
              label-for="mc-bLogo"
            >
              <b-form-file
                id="mc-bLogo"
                v-model="form.company_logo"
                :required="prev_company_logo? false : true"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              />
            </b-form-group>
            <b-input-label-info
              :tooltip-text="businessLogoTip"
              :left="115"
            />
          </b-col>
          <b-col md="6">
            <b-form-group
              class="required"
              label="Company Title"
              label-for="mc-company-title"
            >
              <b-form-input
                id="mc-company-title"
                v-model="form.company_name"
                placeholder="Google Pvt Ltd"
                required
                autocomplete="nope"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              class="required"
              label="Address"
              label-for="mc-address"
            >
              <b-form-textarea
                id="mc-address"
                v-model="form.company_address"
                required
                placeholder="Textarea"
                rows="2"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              class="required"
              label="Telephone No"
              label-for="mc-telNo"
            >
              <b-form-input
                id="mc-telNo"
                v-model="form.company_phone"
                type="text"
                placeholder="0xxxxxxxxx"
                required
                oninput="this.value = this.value.replace(/[^0-9+]/g, '');"
                autocomplete="nope"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              class="required"
              label="Hotline"
              label-for="mc-hotline"
            >
              <b-form-input
                id="mc-hotline"
                v-model="form.company_hotline"
                type="text"
                placeholder="0xxxxxxxxx"
                required
                oninput="this.value = this.value.replace(/[^0-9+]/g, '');"
                autocomplete="nope"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Financial Support Email"
              label-for="mc-fEmail"
            >
              <div class="form-label-group">
                <b-form-input
                  id="mc-fEmail"
                  v-model="form.company_finance_email"
                  type="email"
                  placeholder="abc@gmail.com"
                  autocomplete="nope"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              class="required"
              label-for="mc-currency"
              label="Currency"
            >
              <v-select
                v-model="form.company_currency"
                label="name"
                :options="currencyOptions"
                :reduce="option => option.id"
              >
                <template v-slot:option="option">
                  {{ option.name }}
                </template>
                <template #search="{attributes, events}">
                  <input
                    class="vs__search"
                    style="pointer-events:none"
                    :required="!form.company_currency"
                    v-bind="attributes"
                    v-on="events"
                  >
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              class="required"
              label-for="mc-Country"
              label="Country"
            >
              <v-select
                v-model="form.company_country"
                label="label"
                :options="countryOptions"
                :reduce="option => option.label"
              >
                <template v-slot:option="option">
                  {{ option.label }}
                </template>
                <template v-slot:value="option">
                  {{ option.label }}
                </template>
                <template #search="{attributes, events}">
                  <input
                    class="vs__search"
                    style="pointer-events:none"
                    :required="!form.company_country"
                    v-bind="attributes"
                    v-on="events"
                  >
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              class="required"
              label-for="mc-city"
              label="City"
            >
              <v-select
                v-model="form.company_city"
                label="label"
                :options="cityOptions"
                :reduce="option => option.label"
              >
                <template v-slot:option="option">
                  {{ option.label }}
                </template>
                <template v-slot:value="option">
                  {{ option.label }}
                </template>
                <template #search="{attributes, events}">
                  <input
                    class="vs__search"
                    style="pointer-events:none"
                    :required="!form.company_city"
                    v-bind="attributes"
                    v-on="events"
                  >
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <!-- submit -->
          <b-col class="d-flex justify-content-end">
            <b-button
              v-can="'business-profile-manage'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              class="mr-1 custom-button-color"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BFormTextarea, BFormFile, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import BInputLabelInfo from '@/@core/components/b-input-label-info/BInputLabelInfo.vue'

const ResourceRepository = RepositoryFactory.get('resource')
const BusinessSettingsRepository = RepositoryFactory.get('businessSetting')

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    BFormTextarea,
    BFormFile,
    BOverlay,
    BInputLabelInfo,
    'v-select': vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      selected: null,
      loading: false,
      currencyOptions: [],
      countryOptions: [],
      cityOptions: [],
      form: {},
      prev_company_logo: '',
      businessLogoTip: 'Ensure that the width and height of the logo remain the same when uploading',
    }
  },
  mounted() {
    this.readDataforCurrency()
    this.fetchBusinessSettings()
    this.readDataforCityByCountry()
    this.prev_company_logo = this.form.company_logo
  },
  methods: {
    async fetchBusinessSettings() {
      try {
        this.loading = true
        this.form = localStorage.getItem('businessSettings') ? JSON.parse(localStorage.getItem('businessSettings')) : (await BusinessSettingsRepository.getbusinessSettingsResource()).data.data
        if (!localStorage.getItem('businessSettings')) localStorage.setItem('businessSettings', JSON.stringify(this.form))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async readDataforCurrency() {
      try {
        const { data } = (await ResourceRepository.getCurrencyList()).data
        this.currencyOptions = data
        this.form.company_currency = this.currencyOptions.filter(c => c.currency_code === this.form.company_currency)[0].id
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async readDataforCityByCountry() {
      try {
        const { data } = await ResourceRepository.getCityByCountry()

        this.countryOptions = data.data.map(country => ({
          value: country.id,
          label: country.name,
        }))

        const cities = data.data.flatMap(country => country.cities)

        this.cityOptions = cities.map(city => ({
          value: city.id,
          label: city.name,
        }))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async setDataforProfile() {
      this.loading = true
      try {
        const data = {
          company_name: this.form.company_name,
          company_phone: this.form.company_phone,
          company_hotline: this.form.company_hotline,
          company_address: this.form.company_address,
          company_currency: this.form.company_currency,
          company_finance_email: this.form.company_finance_email,
          company_country: this.form.company_country,
          company_city: this.form.company_city,
        }
        const res = await BusinessSettingsRepository.updatebusinessSettingsResource(data)
        if (res.status === 200) {
          if (this.form.company_logo !== this.prev_company_logo) {
            const logoRes = await BusinessSettingsRepository.updatebusinessSettingsLogoResource(this.form.company_logo)
            if (logoRes.status === 200) {
              this.showSuccessMessage('Profile Updated Successfully')
            } else {
              this.showErrorMessage('Cannot Upload Profile Image')
            }
          } else {
            this.showSuccessMessage('Profile Updated Successfully')
          }
          localStorage.removeItem('businessSettings')
        } else {
          this.showErrorMessage('Cannot Update Profile')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
  },
}
</script>
<style scoped>
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
</style>
